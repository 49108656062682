<template>
  <!-- 头条转换/落地页配置抽屉内容 -->
  <div class="toutiaoAdWebDrawer paddbox">
    <el-card>
      <div>
        <el-table :data="webTableData" style="width: 100%">
          <el-table-column fixed prop="accountName" label="子账户" width="160">
          </el-table-column>
          <el-table-column
            prop="name"
            label="推广内容"
            width="200"
            v-if="msType == 0"
          >
            <template #default="scope">
              <div>
                <el-select
                  :disabled="type == 0"
                  v-model="scope.row.landPageId"
                  placeholder="请先刷新转化追踪"
                >
                  <el-option
                    v-for="item in scope.row.webData"
                    :key="item.siteId"
                    :label="item.name"
                    :value="item.siteId"
                    @click="selectsiteId(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="事件管理优化目标"
            width="200"
            v-if="msType == 0"
          >
            <template #default="scope">
              <div>
                <el-select
                  :disabled="type == 0"
                  v-model="scope.row.external_action"
                  placeholder="请先选择推广内容"
                >
                  <el-option
                    v-for="item in scope.row.assetDataList"
                    :key="item.external_action"
                    :label="item.optimization_name"
                    :value="item.external_action"
                    @click="selectasset_ids(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="检测链接" width="160" v-if="msType == 0">
            <template #default="scope">
              <el-input
                :disabled="type == 0"
                v-model="scope.row.action_track_url"
                placeholder="请输入检测链接"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="落地页"
            width="200"
            v-if="msType == 0"
          >
            <template #default="scope">
              <div>
                <el-select
                  :disabled="type == 1"
                  v-model="scope.row.checked"
                  placeholder="请先刷新转化追踪"
                >
                  <el-option
                    v-for="item in scope.row.webData"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                    @click="setCheckedFun(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="msType == 0"
            prop="checked"
            label="落地页URL"
            :disabled="type == 1"
            width="300"
          >
          </el-table-column>
          <el-table-column v-if="msType == 1" label="事件名称" width="230">
            <template #default="scope">
              <div>
                <el-select
                  v-model="scope.row.checkEvent"
                  placeholder="请选择事件名称"
                >
                  <el-option
                    v-for="item in scope.row.eventNameDate"
                    :key="item.asset_name"
                    :label="item.asset_name"
                    :value="item.asset_name"
                    @click="setGetGoodEventSite(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="msType == 1" label="直达链接" width="300">
            <template #default="scope">
              <el-input
                v-model="scope.row.open_url"
                placeholder="请输入直达链接"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column v-if="msType == 1" label="落地页链接" width="300">
            <template #default="scope">
              <el-input
                v-model="scope.row.checked"
                placeholder="请输入落地页链接"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            prop="target"
            label="短剧优化目标"
            width="230"
            v-if="msType == 0"
          >
            <template #default="scope">
              <div>
                <el-select
                  v-model="scope.row.targeted"
                  placeholder="请选择"
                  :disabled="type == 1"
                >
                  <el-option
                    v-for="item in scope.row.targetData"
                    :key="item.convert_id"
                    :label="item.external_action_name"
                    :value="item.convert_id"
                    @click="setTargeted(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="target"
            label="电商优化目标"
            width="230"
            v-if="msType == 1"
          >
            <template #default="scope">
              <div>
                <el-select
                  v-model="scope.row.checkCommodity"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in scope.row.commodityDate"
                    :key="item.external_action"
                    :label="item.optimization_name"
                    :value="item.external_action"
                    @click="setCommodity(item, scope.$index)"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template #default="scope">
              <div>
                <el-button
                  type="text"
                  @click="refreshFun(scope.row, scope.$index)"
                  >刷新转化追踪</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="qdBtn">
        <el-button type="primary" @click="submitFun()">确定</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject } from 'vue'
import { ElMessage } from 'element-plus'
import {
  getSite,
  convertQuery,
  getSite2,
  geteventConvert,
  getGoodEventConvert,
  getGoodEventSite,
} from '@/request/new/videoBatchManagement'
export default {
  name: 'toutiaoAdWebDrawer',
  components: {},
  // 接收页面的传值
  props: ['data', 'type', 'msType'],
  setup(props, context) {
    const parent = { ...context }

    const state = reactive({
      // 接收传入的子账户数据   accountName子账户 webData落地页数据 checked选中的落地页
      webTableData: props.data,
      // 0转化追踪  1事件管理
      type: props.type,
      // 0短剧  1电商
      msType: props.msType,
      // 获取橙子建站落地页信息
      refreshFun: (data, index) => {
        const datas = {
          id: data.accountName,
        }
        if (state.msType == 1) {
          getGoodEventConvert(datas).then((res) => {
            state.webTableData[index].eventNameDate = res.data
          })
        } else {
          if (state.type == 1) {
            getSite2(datas).then((res) => {
              state.webTableData[index].webData = res.data
            })
          } else {
            getSite(datas).then((res) => {
              state.webTableData[index].webData = res.data
            })
          }
        }
      },
      // 获取转化目标
      convertQueryFun: (data, index) => {
        const datas = {
          id: state.webTableData[index].accountName,
          url: state.webTableData[index].checked,
        }
        convertQuery(datas).then((res) => {
          state.webTableData[index].targetData = res.data
        })
      },
      // 选择落地页
      setCheckedFun: (item, index) => {
        state.convertQueryFun(item, index)
      },
      // 选择推广内容
      selectsiteId: (item, index) => {
        state.webTableData[index].checked = item.value
        state.webTableData[index].asset_idsName = item.name
        state.geteventConvertFun(item, index)
      },
      // 获取资产
      geteventConvertFun: (data, index) => {
        const datas = {
          id: state.webTableData[index].accountName,
          siteId: state.webTableData[index].landPageId,
        }
        geteventConvert(datas).then((res) => {
          state.webTableData[index].assetData = res.data.asset_ids
          state.webTableData[index].assetDataList = res.data.list
        })
      },
      // 选择事件管理优化模版
      selectasset_ids: (item, index) => {
        state.webTableData[index].asset_ids =
          state.webTableData[index].assetData
        state.webTableData[index].external_actionName = item.optimization_name
      },
      // 选择转化目标
      setTargeted: (item, index) => {
        console.log('选择转化目标', item)
        state.webTableData[index].targeted = item.convert_id
        state.webTableData[index].targetedName = item.external_action_name
      },
      // 选择电商优化目标
      setCommodity: (item, index) => {
        state.webTableData[index].checkCommodityName = item.optimization_name
        state.webTableData[index].checkCommodity = item.external_action
      },
      // 获取电商优化目标
      setGetGoodEventSite: (item, index) => {
        const datas = {
          assetId: item.asset_id,
          id: state.webTableData[index].accountName,
        }
        getGoodEventSite(datas).then((res) => {
          state.webTableData[index].asset_ids = res.data.asset_ids
          state.webTableData[index].commodityDate = res.data.list
        })
      },
      // 确定
      submitFun: () => {
        var value = true
        const optTargetDtos = []
        const eventDtos = []
        console.log('state.msType', state.msType)
        if (state.msType == 1) {
          // 电商
          console.log('state.msType', state.webTableData)
          state.webTableData.forEach((element) => {
            if (
              element.checkEvent == '' ||
              element.asset_ids.length == 0 ||
              element.checkCommodity == '' ||
              element.checked == '' ||
              element.open_url == ''
            ) {
              value = false
            } else {
              eventDtos.push({
                accountId: element.accountName,
                asset_ids: element.asset_ids,
                checkEvent: element.checkEvent,
                open_url: element.open_url,
                external_url: element.checked,
                external_action: element.checkCommodity,
                checkCommodityName: element.checkCommodityName,
              })
            }
          })
          if (value == false) {
            return ElMessage({
              type: 'error',
              message: '请把数据填写完整',
            })
          }
          context.emit('getEventDtos', eventDtos)
        } else {
          // 短剧
          if (state.type == 1) {
            // 事件管理
            state.webTableData.forEach((element) => {
              if (
                element.landPageId == '' ||
                element.asset_ids.length == 0 ||
                element.external_action == '' ||
                element.action_track_url == ''
              ) {
                value = false
              } else {
                eventDtos.push({
                  accountId: element.accountName,
                  landPageId: element.landPageId,
                  asset_ids: element.asset_ids,
                  asset_idsName: element.asset_idsName,
                  external_actionName: element.external_actionName,
                  external_action: element.external_action,
                  action_track_url: element.action_track_url,
                })
              }
            })
            if (value == false) {
              return ElMessage({
                type: 'error',
                message: '请把数据填写完整',
              })
            }
            context.emit('getEventDtos', eventDtos)
          } else {
            // 转化追踪
            state.webTableData.forEach((element) => {
              if (element.checked == '' || element.targeted == '') {
                value = false
              } else {
                optTargetDtos.push({
                  accountId: element.accountName,
                  landPageId: element.checked,
                  convert_id: element.targeted,
                  targetedName: element.targetedName,
                })
              }
            })
            if (value == false) {
              return ElMessage({
                type: 'error',
                message: '请把转化/落地页数据填写完整',
              })
            }
            context.emit('getOptTargetDtos', optTargetDtos)
          }
        }
      },
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>
<style lang="scss" scoped>
.formbtn {
  text-align: right;
  margin-right: 50px;
}

.qdBtn {
  margin: 10px;
  float: right;
}
</style>
