<template>
  <!-- 头条广告组配置抽屉内容 -->
  <div class="toutiaoAdsSetDrawer paddbox">
    <el-card>
      <div class="toutiaoAdsSetDraweritem">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="模版名称" prop="modelName">
            <el-input v-model="ruleForm.modelName"></el-input>
          </el-form-item>
          <el-form-item label="广告组预算">
            <el-radio-group v-model="budget_mode" size="large">
              <el-radio-button label="BUDGET_MODE_INFINITE">不限</el-radio-button>
              <el-radio-button label="BUDGET_MODE_DAY">统一日预算</el-radio-button>
              <el-input
                type="number"
                v-if="budget_mode == 'BUDGET_MODE_DAY'"
                v-model="budget"
                placeholder="Please input"
              >
                <template #append>元/天</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告组类型">
            <el-radio-group v-model="campaign_type" size="large">
              <el-radio-button label="FEED">所有广告</el-radio-button>
              <el-radio-button label="SEARCH" disabled>搜索广告</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="营销链路">
            <el-radio-group v-model="marketing_purpose" size="large">
              <el-radio-button label="CONVERSION">行动转化</el-radio-button>
              <!-- <el-radio-button label="统一日预算" disabled>搜索广告</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推广目标">
            <el-radio-group v-model="landing_type" size="large">
              <el-radio-button label="LINK">销售线索收集</el-radio-button>
              <!-- <el-radio-button label="统一日预算" disabled>搜索广告</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开启状态">
            <el-radio-group v-model="operation" size="large">
              <el-radio-button label="enable">开启</el-radio-button>
              <el-radio-button label="disable">关闭</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告组名称" prop="campaign_name">
            <el-input v-model="ruleForm.campaign_name"></el-input>
          </el-form-item>
          <div class="formbtn">
            <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'toutiaoAdsSetDrawer',
  components: {},
  props: ['data'],
  setup(props, context) {
    const parent = { ...context }

    const state = reactive({
      landing_type: 'LINK',
      marketing_purpose: 'CONVERSION',
      campaign_type: 'FEED',
      budget_mode: 'BUDGET_MODE_INFINITE',
      budget: 300,
      operation: 'enable',
      ruleFormRef: '',
      ruleForm: {
        campaign_name: '',
        modelName: '',
      },
      // 必填规则
      rules: {
        modelName: [{ required: true, message: '请输入模版名称', trigger: 'blur' }],
        campaign_name: [{ required: true, message: '请输入广告组名称', trigger: 'blur' }],
      },
      // 确定
      submitForm: async (formEl) => {
        if (!formEl) {
          console.log(formEl)
          return
        }
        // campaignDto 广告组对象
        await formEl.validate((valid, fields) => {
          if (valid) {
            if (state.budget_mode == 'BUDGET_MODE_DAY' && state.budget < 300) {
              ElMessage({
                type: 'error',
                message: '请填写大于300的广告组预算',
              })
              return
            }
            const campaignDto = {
              landing_type: state.landing_type,
              marketing_purpose: state.marketing_purpose,
              campaign_type: state.campaign_type,
              budget_mode: state.budget_mode,
              budget: state.budget,
              operation: state.operation,
              campaign_name: state.ruleForm.campaign_name,
              modelName: state.ruleForm.modelName,
            }

            parent.emit('campaignDto', campaignDto)
          } else {
            console.log('error submit!', fields)
          }
        })
      },
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>
<style lang="scss" scoped>
.formbtn {
  text-align: right;
  margin-right: 50px;
}
</style>
