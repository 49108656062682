/**
 * 百度、头条、ADQ、快手账户配置api接口统一管理
 */
import { get, post } from './http'

// 批量删除账户配置
export const batchDeleteConfigMeidAccount = (data) => post('baidu/batchDeleteConfigMeidAccount', data)


// 批量配置账账户
export const batchConfigMeidAccount = (data) => post('baidu/batchConfigMeidAccount', data)

// 删除单个账户配置
export const deleteConfig = (data) => get('mp/deleteConfig', data)

// 查询用户的姓名
export const selectCurrenDeptUser = (data) => get('user/selectCurrenDeptUser', data)

// 媒体分配账号   填相应的字段 mediaName: 'adq'
export const disMediaAccount = (data) => post('baidu/disMediaAccount', data)

// 媒体总和数据   tableName:
export const baidurealTimexiaohaoData = (data) => get('baidu/baidurealTimexiaohaoData', data)

// 查询全部人的数据汇总 tableName:
export const baidurealTimexiaohaototalData = (data) => get('baidu/baidurealTimexiaohaototalData', data)

// =============================百度============================================

// 添加百度超管账号
export const saveBiduAccount = (data) => post('baidu/saveBiduAccount', data)

// 同步百度超管账号下的子账号
export const syncBaiduSonAccount = (data) => get('baidu/syncBaiduSonAccount', data)

// 查询所有超管百度账号
export const baiduAccountSelectAll = (data) => get('baidu/baiduAccountSelectAll', data)

// 查询百度的子账号
export const baiduSonAccountSelectAll = (data) => get('baidu/baiduSonAccountSelectAll', data)

// 百度账号配置列表
export const baiduMediaConfig = (data) => get('baidu/baiduMediaConfig', data)

// 百度账户配置保存
export const configMeidAccount = (data) => post('baidu/configMeidAccount', data)

// 百度实时数据列表
export const baidurealTimeData = (data) => get('baidu/baidurealTimeData', data)


// 查询所有超管百度账号2
export const selectAll = (data) => get('outh2/selectAll', data)




// =============================头条============================================

// 查询超管账号
export const toutiaoAccountSelectAll = (data) => get('toutiao/toutiaoAccountSelectAll', data)

// 同步头条超管账号下的子账号
export const syncToutiaoSonAccount = (data) => post('toutiao/syncToutiaoSonAccount', data)

// 查询头条子账户列表数据
export const toutiaoSonAccountSelectAll = (data) => get('toutiao/toutiaoSonAccountSelectAll', data)

// 头条配置页面查询
export const toutiaoMediaConfig = (data) => get('toutiao/toutiaoMediaConfig', data)

// 头条广告主数据
export const toutiaorealTimeData = (data) => get('toutiao/toutiaorealTimeData', data)

// 头条广告实际付费成本
export const selectfastOrderZhs = (data) => get('/toutiao/selectfastOrderZhs', data)

// 实际转化数和转化成本
export const selectGzhOrderZhs = (data) => get('/toutiao/selectGzhOrderZhs', data)

// 修改超管账号名称
export const updateAccountName = (data) => get('/toutiao/updateAccountName', data)

// 修改超管账号名称
export const selectEmailByToutiaoSuperAccount = (data) => get('/toutiao/selectEmailByToutiaoSuperAccount', data)






// =============================adq============================================

// 查询adq超管账号
export const adqSuperAccountSelectAll = (data) => get('adq/adqSuperAccountSelectAll', data)

// 同步子账户
export const syncAdqSonAccount = (data) => get('adq/syncAdqSonAccount', data)

// 查询adq子账户列表数据
export const adqSonAccountSelectAll = (data) => get('adq/adqSonAccountSelectAll', data)

// adq配置页面查询
export const adqMediaConfig = (data) => get('baidu/adqMediaConfig', data)

// adq实时数据列表
export const adqurealTimeData = (data) => get('adq/adqurealTimeData', data)

// =============================mp============================================

// 查询mp超管账号
export const mpSuperAccountSelectAll = (data) => get('mp/mpSuperAccountSelectAll', data)

// 同步子账户
export const syncmpSonAccount = (data) => get('mp/syncMpSonAccount', data)

// 查询mp子账户列表数据
export const mpSonAccountSelectAll = (data) => get('mp/mpSonAccountSelectAll', data)

// mp配置页面查询
export const mpMediaConfig = (data) => get('mp/mpMediaConfig', data)

// mp实时数据列表
export const mpurealTimeData = (data) => get('mp/mpurealTimeData', data)

// mp切换类型 0一句话  1ROI
export const configRoi = (data) => get('mp/configRoi', data)




// =============================快应用头条============================================

// 快应用头条广告主数据
export const fasttoutiaorealTimeData = (data) => get('toutiao/fasttoutiaorealTimeData', data)


// =============================快手============================================

// 查询快手超管账号
export const kuaishouAccountSelectAll = (data) => get('kuaishou/kuaishouAccountSelectAll', data)

// 同步超管账号下的子数据
export const syncKuaiShouSonAccount = (data) => post('kuaishou/syncKuaiShouSonAccount', data)

// 查询快手子账号信息
export const kuaiShouSonAccountSelectAll = (data) => get('kuaishou/kuaiShouSonAccountSelectAll', data)





