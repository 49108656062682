<template>
  <!-- 选中子账户组件 -->
  <div class="div-main flex">
    <div class="title">超管账户：</div>
    <div class="flex">
      <div>
        <el-select
          v-model="checksuperAccount"
          filterable
          placeholder="请选择超管账户"
          @change="requestSonAccountFun()"
        >
          <el-option
            v-for="item in superAccountList"
            :key="item.id"
            :label="item.advertiserName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button
          type="primary"
          @click="selectson()"
          :disabled="sonBtnValue"
          >{{
            sonDateSelected.length == 0 ? '请选择子账户' : '已选择'
          }}</el-button
        >
      </div>
    </div>
    <div class="title">
      <el-tooltip
        class="item"
        effect="dark"
        :content="sonString"
        placement="right"
      >
        <div>已选:({{ sonDateSelected.length }})</div>
      </el-tooltip>
    </div>
  </div>

  <!-- 添加弹窗  -->
  <el-dialog title="选择子账户" v-model="sonVisibles" width="700px">
    <div>
      <el-transfer
        v-model="sonDateSelected"
        filterable
        :filter-method="filterMethod"
        filter-placeholder="请输入账号"
        :data="sonAccountList"
      />
    </div>
    <div class="downBtn">
      <el-button type="primary" @click="addSonLists()">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, toRefs, inject, watch, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import {
  toutiaoAccountSelectAll,
  toutiaoSonAccountSelectAll,
} from '@/request/accountManagement'
export default {
  name: 'selectSonAccount',
  components: {},
  // 接收页面的传值
  props: ['data'],
  setup(props, context) {
    const state = reactive({
      // 接收传入看是否是新增或者临时   type是 0是临时进来的  1是新增进来
      typeValue: props.data.type,

      // 超管账户
      superAccountList: [],
      // 选中的超管账户
      checksuperAccount: '',

      // 子账户
      sonAccountList: [],
      // 子账号的数据选中的数据
      sonDateSelected: [],
      // 子账户字符串
      sonString: '',
      //默认查询子全部
      userName: '',
      // 子账户弹窗控制
      sonVisibles: false,
      // 请选择子账号按钮 绑定值
      sonBtnValue: true,

      //获取子账户数据
      requestSonAccountFun: () => {
        state.sonString = ''
        if (state.typeValue == 'toutiao') {
          const datas = {
            sid: state.checksuperAccount,
            userName: state.userName,
            currentPage: 1,
            size: 10000,
          }
          toutiaoSonAccountSelectAll(datas).then((res) => {
            state.sonDateSelected = []
            state.sonAccountList = []
            // for (let i = 0; i < res.data.data.length; i++) {
            //   state.sonAccountList.push({
            //     label: res.data.data[i].advertiserIdSon,
            //     key: res.data.data[i].id,
            //     spell: res.data.data[i].advertiserIdSon,
            //   })
            // }
            res.data.data.forEach((item) => {
              state.sonAccountList.push({
                label: JSON.stringify(item.advertiserIdSon),
                key: item.advertiserIdSon,
                spell: item.advertiserIdSon,
              })
            })
            state.sonBtnValue = false
          })
        }
      },
      // 请选择子账户按钮
      selectson: () => {
        state.sonVisibles
          ? (state.sonVisibles = false)
          : (state.sonVisibles = true)
      },
      // 选择子账户的确定
      addSonLists: () => {
        if (state.sonDateSelected.length > 10) {
          return ElMessage({
            type: 'error',
            message: '选中账号不能超过10个',
          })
        }
        if (state.sonDateSelected.length > 0) {
          state.sonBtnValue = true
          state.sonString = ''
          state.sonDateSelected.forEach((item, index) => {
            if (state.sonDateSelected.length - 1 == index) {
              state.sonString += item
            } else {
              state.sonString += item + '，'
            }
          })
          const datas = {
            sonDateSelected: state.sonDateSelected,
          }

          context.emit('getSelectSonAccountData', state.sonDateSelected)
        }
        state.selectson()
      },
    })

    // 根据情况请求超管账户
    const requestFun = () => {
      if (state.typeValue == 'toutiao') {
        const datas = {
          currentPage: 1,
          size: 1000,
        }
        toutiaoAccountSelectAll(datas).then((res) => {
          state.superAccountList = res.data.data
        })
      }
    }
    requestFun()

    return { ...toRefs(state), requestFun }
  },
}
</script>

<style lang="scss" scoped>
.btn {
  justify-content: center;
  > div {
    margin: 4%;
  }
}

.title {
  padding-top: 12px;
}

.div-main {
  > div {
    margin-right: 6px;
  }
}

.downBtn {
  float: right;
}

/deep/ .el-transfer-panel {
  width: auto !important;
}
</style>
